import Cookies from 'js-cookie'

const setCookie = (name, value, days) => {
    Cookies.set(name, value, { expires: days })
}

const getCookie = (name) => {
    let vars = Cookies.get(name);
    return vars;
}

const delCookie = (name) => {
    Cookies.remove(name);
}

export {
    setCookie,
    getCookie,
    delCookie
}