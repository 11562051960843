<template>
    <div>
      <main class="z-0 hidden h-screen w-screen min-w-[1120px] flex-col items-center justify-center gap-0 md:flex">
        <header class="flex h-12 min-h-[48px] w-full items-center justify-between border-b-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-4">
          <button class="flex select-none items-center overflow-hidden rounded-md transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--label-title)] focus:ring-offset-4 focus:ring-offset-[var(--bg-sub)]">
            <img src="../assets/images/logo.png" />
          </button>
  
          <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4">
            <a href="/" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">首页</a>
              <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important; border: none !important;">
                      <span>文创鞋款</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/txttoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">文创女鞋</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/mentxttoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">文创男鞋</label></a>
                        </div>
                      </form>
                      </PopoverPanel>
                    </transition>
              </Popover>
              <a href="/imgtoimg" class="text-gray-300 text-white px-3 py-2 rounded-md text-sm font-medium" aria-current="page">以图生图</a>
              <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important; border: none !important;">
                      <span>款式合成</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/multitoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">多款合成</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/styletoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">款式融合</label></a>
                        </div>
                        <div class="flex items-center">
                         <a href="/rembg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">AI褪底</label></a>
                        </div>
                      </form>
                      </PopoverPanel>
                    </transition>
              </Popover>
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
                  <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important;
      border: none !important;">
                      <span>款式广场</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
  
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/mypic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">我的作品</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/myfav"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">我的收藏</label></a>
                        </div>
                        <div class="flex items-center">
                        <a href="/pic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">女鞋款式</label></a>
                        </div>
                        <div class="flex items-center">
                        <a href="/menpic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">男鞋款式</label></a>
                        </div>
                      </form>
                      </PopoverPanel>
                    </transition>
                  </Popover>
            </div>
            
  
          <div class="flex items-center gap-4">
            <div class="flex items-center gap-2">
             
            </div>
            <div class="h-4 w-px bg-[var(--bg-shade)]"></div>
            <div class="flex items-center gap-2">
              <Popover class="relative inline-block px-4 text-left">
              <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important;
    border: none !important;">
             <button type="button" class="select-none whitespace-nowrap font-medium transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-2 pr-3 h-8 gap-1.5 text-sm border border-solid border-[var(--bg-border)] bg-[var(--bg-base-hover)] text-[var(--label-base)] hover:bg-[var(--bg-shade)] hover:text-[var(--label-title)] focus:ring-[var(--label-title)] cursor-pointer z-50 flex items-center justify-center focus:outline-none rounded-full" data-state="closed" data-type="secondary">
             
              会员中心
            </button> 
          
                  
            </PopoverButton>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <form class="space-y-4">
                        <div class="flex items-center">
                       <a href="/profile"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">会员中心</label></a> 
                      </div>
                      <div class="flex items-center">
                       <a href="/repassword"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">修改密码</label></a>
                      </div>
                      <div class="flex items-center">
                      <a href="/conlog"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">消费记录</label></a>
                      </div>
                      <div class="flex items-center">
                      <a href="/?ac=loginout"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">退出登录</label></a>
                      </div>
                    </form>
                    </PopoverPanel>
                </transition>
            </Popover>
            </div>
          </div>
        </header>
        <div class="relative flex h-full max-h-[calc(100%-48px)] w-full justify-end">
        
          <!--中间开始 start-->
          <div class="main-box mb-4" >
            <div class="h-full max-h-[calc(100%-40px)] flex items-center gap-3 rounded-3xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-3 shadow-md mt-4 mb-4" data-projection-id="12" style="opacity: 1; transform: none;">
              <div class="relative flex h-full h-full w-full items-center justify-center" style="padding:20px">
                <div style="width: 100%; height: 100%; background-size: 20px 20px; background-repeat: repeat; background-image: radial-gradient(circle at 1px 1px, #8e8e8e 1px, transparent 0); justify-content: space-between; align-items: center; flex-direction: column; display: flex;">    <!-- <div class="picbg" style="position: absolute;top:15px;left:5px;right:5px;bottom:15px;border-radius: 25px;"></div> -->
                    <canvas data-engine="three.js r152" style="display: block; width: 100%; height: 100%;overflow:hidden"></canvas>
                    <div style="position: absolute; left: 35%; top: 40%; transform: translate(-25%, -40%); border-radius: 25px;"><img :src="hpic.value" /></div>
               </div> 
               </div>
            </div>
            
          </div>
          <!--中间开始 end-->
              
        </div>
      </main>
        <div class="flex fixed items-center p-6 max-w sm mx-auto bg-gray-200 rounded-lg space-x-4 a z-10 " style="left:45%;top:40%" v-show="onshow.value">
          <div>
            <div class="text-xl font-medium text-black">{{message}}</div>
            <p class="text-slate-500">
              <button type="button" class="bg-white mr-4 w-32 py-2 px-3 mt-1 rounded-md border border-gray-300" @click="clicle">确 定</button>
            </p>
          </div>
        </div>
      </div>
    </template>
    <style>
    [data-radix-scroll-area-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch;}[data-radix-scroll-area-viewport]::-webkit-scrollbar{display:none}
    
    </style>
    
    <script setup>
    import { modellist,rembg,vitoken,picinfo } from '../api/ajaxApi';
    import {setCookie,getCookie,delCookie} from '../utils/cookieok';
    import {Msg} from '@/utils/tools';
    import { RadioGroup, RadioGroupLabel, RadioGroupOption,Popover, PopoverButton, PopoverGroup, PopoverPanel,Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
    import {
    ArrowPathIcon,
    Bars3Icon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    DocumentChartBarIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
    } from '@heroicons/vue/24/outline'
    import { useRouter } from 'vue-router'
    const router = useRouter()
    const route = router.value;
    import { ChevronDownIcon } from '@heroicons/vue/20/solid'
    import {
        ref,
        onMounted, // 组件加载完成时调用
        onUpdated, // 组件更新完成时调用
        onUnmounted, // 组件卸载完成时调用
        onBeforeMount, // 组件加载前时调用
        onBeforeUpdate, // 组件更新前时调用
        onBeforeUnmount, // 组件卸载前时调用
        onActivated, // 组件激活时时调用
        onDeactivated, // 组件失活时时调用
        onErrorCaptured, // 捕获到后代组件的错误时调用
        onRenderTracked, // 状态跟踪，(组件更新时，跟踪组件里边每个值和方法的变化)
        onRenderTriggered, // 状态触发，(虚拟 DOM 重新渲染被触发时调用,此事件告诉你是什么操作触发了重新渲染，以及该操作的目标对象和键)
        onServerPrefetch, // 服务器上被渲染之前调用
    } from "vue";
    
    const selectedmaterial=ref(0); // 存储所选值的变量元素
    const options=ref([ // 下拉列表的选项
                  { value: '0', text: '选择元素' },
                  { value: '1', text: '蝴蝶结' },
                  { value: '2', text: '立体花' }
                ]);
    const selectedelement=ref(0);//材质
    const elements=ref([ 
                 { value: '0', text: '选择材料' },
                  { value: '3', text: '蛇纹' },
                  { value: '4', text: '牛仔布' },
                  {value: '7', text: ' 鳄鱼纹' },
                  {value:'8',text:'豹纹'},
                  {value:'9',text:'蕾丝面料'},
                  {value:'10',text:'格纹面料'}
                ]);
    const selectedhead=ref(0)
    const heads=ref([ 
                { value: '0', text: '选择头型 ' },
                { value: '6', text: '尖头' },
                { value: '5', text: '方头' }]);
    
    const colors = [
      { name: 'white',bgColor:'bg-white-500',selectedColor: 'ring-white-500',value:'1' },
      { name: 'black', bgColor: 'bg-black-500', selectedColor: 'ring-black-500',value:'2' },
      { name: 'brown',bgColor:'bg-brown-500',selectedColor: 'ring-brown-500',value:'3' },
      { name: 'red',bgColor:'bg-red-500',selectedColor: 'ring-red-500',value:'4' },
      { name: 'Yellow', bgColor: 'bg-yellow-500', selectedColor: 'ring-yellow-500',value:'5' },
      { name: 'Purple', bgColor: 'bg-purple-500', selectedColor: 'ring-purple-500',value:'6' },
      
    ]
    const colors2 = [
    { name: 'Blue', bgColor: 'bg-blue-500', selectedColor: 'ring-blue-500',value:'7' },
      { name: 'gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500',value:'8' },
      { name: 'Green', bgColor: 'bg-green-500', selectedColor: 'ring-green-500',value:'9' },
      { name: 'pink',bgColor:'bg-pink-500',selectedColor: 'ring-pink-500',value:'10' }
    ]
    
    const navigation = [
    { name: '局部重绘', href: '/extratoimg', current: false },
    { name: '配色换料', href: '/colortoimg', current: false },
    { name: '手稿配色', href: '/cannytoimg', current: false },
    { name: '换底换面', href: '/sutoimg', current: false },
  ]
    const selectedColor = ref(colors[1])  //颜色
  
    const trendylist=ref([]);
    const casuallist=ref([]);
    const fashionlist=ref([]);
    let thumbnailBase64=ref('');
    let picshow=ref(false);
    const brushsize=ref(0.27);

    const onshow=ref(false);
    const message=ref("");

    function clicle(){
     onshow.value=!onshow.value
    }
    function delpic(){
      picshow.value=!picshow.value;
    }
    function deltxt(){
    prompt.value=''
    }
    const memoryOptions = [
    { name: '1 张',value:1, inStock: true },
    { name: '2 张',value:2, inStock: true },
    { name: '4 张',value:4, inStock: true },
  ]

  const picsize = [
    { name: '512*512',value:1, inStock: true },
    { name: '1024*1024',value:2, inStock: true },
  ]
  //图片上传处理开始
  const fileInput = ref(null);
  const handleDrop = (event) =>{
    const file = event.dataTransfer.files[0];
    var image = new Image();
    console.log(file.type)
   if(file.type != 'image/png'&&file.type != 'image/jpeg'&&file.type != 'image/gif'&&file.type != 'image/webp'){
          return false;
     }
    
   if(file.size>1024*102400){}else{
   
        image.src = window.URL.createObjectURL(file);
        image.onload = function(){
            var w = image.width,h = image.height,
            scale = w / h;
            if(scale>1){
                w = 512;
                h = w / scale;
            }else{
                h=512;
                w=h*scale;
            }
            // 默认图片质量为0.7，quality值越小，所绘制出的图像越模糊
            var quality = 1;
            //生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // 创建属性节点
            var anw = document.createAttribute("width");
            anw.nodeValue = 512;
            var anh = document.createAttribute("height");
            anh.nodeValue = 512;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);
            var dx=0;
            var dy=0;
            if(w<512){
                dx=(512-w)/2
            }
            if(h<512){
                dy=(512-h)/2
            }
            ctx.drawImage(image, dx, dy, w, h);
            var ext = image.src.substring(image.src.lastIndexOf(".")+1).toLowerCase();//图片格式
            var base64 = canvas.toDataURL("image/"+ext, quality );
                thumbnailBase64.value=ref(base64);
                picshow.value=ref(true);
                // 回调函数返回base64的值
            }
        }
  }
  const openFileInput = () => {
    fileInput.value.click();
   };
   const handleFileInput = (event) => {
   const file = event.target.files[0];
   var image = new Image();
   if(file.type != 'image/png'&&file.type != 'image/jpeg'&&file.type != 'image/gif'&&file.type != 'image/webp'){
          return false;
     }
   if(file.size>1024*102400){}else{
        image.src = window.URL.createObjectURL(file);
        image.onload = function(){
            var w = image.width,h = image.height;
            // scale = w / h;
            // if(scale>1){
            //     w = 512;
            //     h = w / scale;
            // }else{
            //     h=512;
            //     w=h*scale;
            // }
            // // 默认图片质量为0.7，quality值越小，所绘制出的图像越模糊
             var quality = 1;
            // //生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // // 创建属性节点
             var anw = document.createAttribute("width");
             anw.nodeValue = w;
             var anh = document.createAttribute("height");
             anh.nodeValue = h;
             canvas.setAttributeNode(anw);
             canvas.setAttributeNode(anh);
            // var dx=0;
            // var dy=0;
            // if(w<512){
            //     dx=(512-w)/2
            // }
            // if(h<512){
            //     dy=(512-h)/2
            // }
            ctx.drawImage(image, 0, 0, w, h);
            var ext = image.src.substring(image.src.lastIndexOf(".")+1).toLowerCase();//图片格式
            var base64 = canvas.toDataURL("image/"+ext, quality );
            thumbnailBase64.value=ref(base64);
            picshow.value=ref(true);
            }
        }
        
    }
  //图片上传处理结束
  function hrefurl(url){
    router.push(url);
  }

  onMounted(() => {
    document.title = '图片选择';
   // binstyle()
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('id')) {
      let id = urlParams.get('id');
      if (id)
      {
          let para={
          token:getCookie("usertoken"),
          id:id
          }
          picinfo(para).then(res=>{
            if(res.success){
              thumbnailBase64.value =ref(res.data.imgpath);
              picshow.value=ref(true);
            }
          })
      }
    }
  })

  function binstyle(){
    let params={
        token:getCookie("usertoken"),
    }
    vitoken(params).then(res => {
        if (res.success) {
        }
        else
        {
            router.push('/login');
        }
        })
        // modellist(params).then(res => {
        // if (res.success) {
        //     trendylist.value = res.data.trendy.map(item => ({ ...item, style: 'border-gray-300' }));
        //     casuallist.value=res.data.casual.map(item => ({ ...item, style: 'border-gray-300' }));
        //     fashionlist.value=res.data.fashion.map(item => ({ ...item, style: 'border-gray-300' }));
        // }
        // else
        // {
        //     router.push('/login');
        // }
        //})
  }

  const tabs = [
    {name: '潮鞋'},
    {name: '休闲鞋',},
    {name: '时装鞋',}
  ]
  const selectnum = ref(memoryOptions[1])//数量
  const selectpicsize=ref(picsize[0])
  const negprompt=ref("")//反提示词
  const prompt=ref("")   //提示词
  const scalesize=ref(7)   //权重
  const stheme=ref(0)  
  function selecttheme(theme){
    stheme.value=ref(theme)  //主题
  }
  
  const piclist=ref([])
  const hpic=ref("")
  function clickpic(pic){
    hpic.value=ref(pic)
  }
  function makepic(){
        if(thumbnailBase64.value.value==null){
          onshow.value=ref(true)
          message.value="请先上传图片"
        }
        else{
             Msg.loading();
             let params={
                // bigclass:stheme.value,
                // prompt:prompt.value,
                // negprompt:negprompt.value,
                // colors:selectedColor.value.value,
                // num:selectnum.value.value,
                // size:selectpicsize.value.value,
                // scale:scalesize.value,
                // material:selectedmaterial.value,
                // element:selectedelement.value,
                // head:selectedhead.value,
                // brushsize:brushsize.value,
                b64:thumbnailBase64.value.value,
                
                token:getCookie("usertoken")
            }
            rembg(params).then(res => {
                Msg.hideLoading();
               if (res.success) {
                    piclist.value=res.data
                    hpic.value=ref(res.data[0].bigpath)
                }
                else{
                    onshow.value=ref(true)
                    message.value=res.msg
                }
             })
        }
    }
    </script>
    
 