import axios from 'axios';
import { loginreq, req, upfile } from './axiosFun';

// 登录接口 
export const login = (params) => { return loginreq("post", "api/login", params) };
// 验证码
export const getcode = () => { return req("get", "api/imgcode") };
//注册
export const reg = (params) => { return loginreq("post", "api/reg", params) };
//取回密码
export const forgotpass = (params) => { return loginreq("post", "api/forgotpass", params) };
//验证码
export const getmcode = (params) => { return loginreq("post", "api/GetCode", params) };
//首页图片
export const homepic = () => { return req("get", "api/homepic") };
//精选图片
export const jxpiclist = (params) => { return loginreq("post", "api/picmanage", params) };
//模型
export const modellist = (params) => { return loginreq("post", "api/shoesmodel", params) };
//文生图
export const txttoimg = (params) => { return loginreq("post", "api/txttoimg", params) };
//反推提示词
export const imgtotxt = (params) => { return loginreq("post", "api/imgtotxt", params) };
//图生图
export const imgtoimg = (params) => { return loginreq("post", "api/imgtoimg", params) };
//图生图
export const aktoimg = (params) => { return loginreq("post", "api/ak2img", params) };
//多款融合
export const variety = (params) => { return loginreq("post", "api/variety", params) };
//款式合成
export const styletoimg = (params) => { return loginreq("post", "api/styletoimg", params) };
//配色配料
export const colortoimg = (params) => { return loginreq("post", "api/colortoimg", params) };
//手稿上色
export const cannytoimg = (params) => { return loginreq("post", "api/cannytoimg", params) };
//图转线稿
export const imgtocanny = (params) => { return loginreq("post", "api/imgtocanny", params) };
//局部重绘
export const extratoimg = (params) => { return loginreq("post", "api/extratoimg", params) };
//我的创作
export const mypiclist = (params) => { return loginreq("post", "api/mypicmanage", params) };
//我的收藏
export const myfavelist = (params) => { return loginreq("post", "api/myfavpic", params) };
//验证token
export const vitoken = (params) => { return loginreq("post", "api/vitoken", params) };
//获取图片信息
export const picinfo = (params) => { return loginreq("post", "api/getpic", params) };
//上传图片
export const upfilepic = (params) => { return upfile("post", "api/upload", params) };
//获取颜色
export const getcolor = (params) => { return loginreq("post", "api/colorlist", params) };
//获取皮料
export const getleather = (params) => { return loginreq("post", "api/leatherlist", params) };
//获取换底换面
export const vamptoimg = (params) => { return loginreq("post", "api/vamptoimg", params) };
//多款融合上传图片
export const spicupfile = (params) => { return loginreq("post", "api/upfile", params) };
//多款融合
export const imgmixer = (params) => { return loginreq("post", "api/mixertoimg", params) };
//会员信息
export const userinfo = (params) => { return loginreq("post", "api/userinfo", params) };
//修改密码
export const savepass = (params) => { return loginreq("post", "api/savepassword", params) };
//去除图片背景
export const rembg = (params) => { return loginreq("post", "api/rembg", params) };