<template>
    <div>
      <main class="min-w-[1120px] flex-col items-center justify-center gap-0 md:flex">
       <Header></Header>
       
        <div class="w-full max-w-[1625px]">
            <div>
                
            <div class="w-full mx-auto py-3 sm:items-center">
                <div class="flex flex-wrap items-center justify-start gap-2 pt-4">
                <button  class="relative inline-flex h-7 select-none items-center gap-1 rounded-lg px-2 text-xs font-medium outline-none transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)]">
                    <span class="whitespace-nowrap transition duration-100 ease-out">潮鞋</span>
                </button>
                 <button v-for="cx in cxs" :key="cx.value" class="relative inline-flex h-7 select-none items-center gap-1 rounded-lg px-2 text-xs font-medium outline-none transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 bg-[var(--bg-base-hover)] text-[var(--label-base)] hover:bg-green-100 hover:text-[var(--label-title)] focus:ring-[var(--label-title)]"  @click="clikcls(cx.value)">
                     <span class="whitespace-nowrap transition duration-100 ease-out">{{ cx.label }}</span>
                 </button>
            </div>
            <div class="flex flex-wrap items-center justify-start gap-2 pt-4">
                <button  class="relative inline-flex h-7 select-none items-center gap-1 rounded-lg px-2 text-xs font-medium outline-none transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)]">
                    <span class="whitespace-nowrap transition duration-100 ease-out">休闲</span>
                </button>
                 <button v-for="xs in xss" :key="xs.value" class="relative inline-flex h-7 select-none items-center gap-1 rounded-lg px-2 text-xs font-medium outline-none transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 bg-[var(--bg-base-hover)] text-[var(--label-base)] hover:bg-grey hover:text-[var(--label-title)] focus:ring-[var(--label-title)]"  @click="clikcls(xs.value)">
                     <span class="whitespace-nowrap transition duration-100 ease-out">{{ xs.label }}</span>
                 </button>
            </div>
            <div class="flex flex-wrap items-center justify-start gap-2 pt-4">
                <button  class="relative inline-flex h-7 select-none items-center gap-1 rounded-lg px-2 text-xs font-medium outline-none transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)]">
                    <span class="whitespace-nowrap transition duration-100 ease-out">时装</span>
                </button>
                 <button v-for="sz in szs" :key="sz.value" class="relative inline-flex h-7 select-none items-center gap-1 rounded-lg px-2 text-xs font-medium outline-none transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 bg-[var(--bg-base-hover)] text-[var(--label-base)] hover:bg-grey hover:text-[var(--label-title)] focus:ring-[var(--label-title)]" @click="clikcls(sz.value)">
                     <span class="whitespace-nowrap transition duration-100 ease-out">{{ sz.label }}</span>
                 </button>
                 
            </div>
              <!-- <h3 class="text-sm font-medium text-gray-200">
                选择
                <span class="sr-only">, active</span>
              </h3> -->

              <!-- <div aria-hidden="true" class="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />

              <div class="mt-2 sm:mt-0 sm:ml-4">
                <div class="-m-1 flex flex-wrap items-center">
                  <span v-for="activeFilter in activeFilters" :key="activeFilter.value" class="m-1 inline-flex items-center rounded-full border border-gray-200 border-none bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)] py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900">
                    <span>{{ activeFilter.label }}</span>
                    <button type="button" class="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500">
                      <span class="sr-only">Remove filter for {{ activeFilter.label }}</span>
                      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button>
                  </span>
                </div>
              </div> -->
            </div>
          </div>

            <div class="mt-6 space-y-12 lg:grid lg:grid-cols-4 lg:gap-x-6 lg:space-y-0" id="img-area">
            <div v-for="(callout,index) in items" :key="callout.name" class="group relative" @click="previewimage(index)">
            <div class="relative h-100 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-164 lg:aspect-w-1 lg:aspect-h-1">
              <img  :data-original="callout.imgpath" :src="callout.sampath" :alt="callout.pattername" class="h-full w-full object-cover object-center" />
            </div>
            <h3 class="mt-6 text-sm text-gray-200">
              <a :href="callout.href">
                <span class="absolute inset-0" />
                {{ callout.pattername }}
              </a>
              <button @click="clikimgtoimg(callout.id)" class="relative ml-4 inline-flex h-7 select-none items-center gap-1 rounded-lg px-2 text-xs font-medium outline-none transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)]">
                    <span class="whitespace-nowrap transition duration-100 ease-out">生成同款</span>
                </button>
                <button @click="clikextratoimg(callout.id)"  class="relative ml-4 inline-flex h-7 select-none items-center gap-1 rounded-lg px-2 text-xs font-medium outline-none transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)]">
                    <span class="whitespace-nowrap transition duration-100 ease-out">修改款式</span>
                </button>
            </h3>
            <p class="text-base font-semibold text-gray-900">{{ callout.pattername }}</p>
            </div>
           </div>
        </div>
        <div v-if="showButton" class="fixed bottom-4 right-6" @click="scrollToTop"><img src="../assets/images/top.png" ></div>
        <div v-if="loading">加载中...</div>
      </main>
      </div>
    </template>
    
    <script setup>
    import Viewer from 'viewerjs'
    import { RadioGroup, RadioGroupLabel, RadioGroupOption,Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
    import {
    ArrowPathIcon,
    Bars3Icon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    DocumentChartBarIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
    } from '@heroicons/vue/24/outline'
    import { ChevronDownIcon } from '@heroicons/vue/20/solid'
    import { mypiclist,vitoken } from '../api/ajaxApi';
    import Header from '../components/Header.vue'
    import {Pagination} from 'vue-laravel-ui'
    import { getCookie } from '../utils/cookieok'
    import { useRouter } from 'vue-router'
    import 'viewerjs/dist/viewer.css'
    import {
        ref,
        onMounted, // 组件加载完成时调用
        onUpdated, // 组件更新完成时调用
        onUnmounted, // 组件卸载完成时调用
        onBeforeMount, // 组件加载前时调用
        onBeforeUpdate, // 组件更新前时调用
        onBeforeUnmount, // 组件卸载前时调用
        onActivated, // 组件激活时时调用
        onDeactivated, // 组件失活时时调用
        onErrorCaptured, // 捕获到后代组件的错误时调用
        onRenderTracked, // 状态跟踪，(组件更新时，跟踪组件里边每个值和方法的变化)
        onRenderTriggered, // 状态触发，(虚拟 DOM 重新渲染被触发时调用,此事件告诉你是什么操作触发了重新渲染，以及该操作的目标对象和键)
        onServerPrefetch, // 服务器上被渲染之前调用
    } from "vue";
    const cxs = [{ value: '9', label: '老爹鞋' },{ value: '21', label: '断底老爹鞋' },{ value: '25', label: '老爹凉鞋' },{ value: '29', label: '厚底老爹' },{ value: '3', label: '板鞋' },{ value: '28', label: '厚底板鞋' },{ value: '5', label: '溶解鞋' },{ value: '13', label: '阿甘鞋' },{ value: '7', label: '小白鞋' },{ value: '10', label: '面包鞋' },{ value: '8', label: '小脏鞋' },{ value: '4', label: '气垫鞋' },{ value: '6', label: '运动鞋' },{ value: '37', label: '未来老爹' },{ value: '38', label: '动感运动' },{ value: '39', label: '厚底凉鞋' },{ value: '40', label: '厚底小白鞋' },{ value: '41', label: '厚底运动鞋' }]
    const xss = [{ value: '20', label: '休闲马丁' },{ value: '23', label: '厚底马丁' },{ value: '19', label: '马丁靴' },{ value: '31', label: '休闲凉鞋' },{ value: '32', label: '中性凉鞋' },{ value: '1', label: '玛丽珍' },{ value: '2', label: '烟筒靴' },{ value: '26', label: '厚底乐福鞋' },{ value: '24', label: '厚底烟筒' },{ value: '27', label: '厚底玛丽珍' },{ value: '12', label: '渔夫鞋' },{ value: '14', label: '奶奶鞋' },{ value: '15', label: '乐福鞋' },{ value: '17', label: '中性鞋' },{ value: '18', label: '休闲单鞋' },{ value: '30', label: '中性单鞋' }]
    const szs = [{ value: '34', label: '高水台单鞋' },{ value: '33', label: '高跟单鞋' },{ value: '42', label: '粗跟单鞋' },{ value: '43', label: '细跟水台单鞋' },{ value: '44', label: '金属跟单鞋' },{ value: '45', label: '异形跟单鞋' },{ value: '46', label: '异形跟水台单鞋' },{ value: '47', label: '坡跟单鞋' },{ value: '48', label: '鱼嘴鞋' },{ value: '49', label: '中后空单鞋' },{ value: '50', label: '中后空水台单鞋' },{ value: '51', label: '中跟单鞋' },{ value: '52', label: '时装凉鞋' },{ value: '53', label: '细跟凉鞋' },{ value: '54', label: '细跟水台凉鞋' },{ value: '55', label: '粗跟凉鞋' },{ value: '56', label: '粗跟水台凉鞋' },{ value: '57', label: '金属跟凉鞋' },{ value: '58', label: '异形跟凉鞋' }]
    const items = ref([]);
    const loading = ref(false);
    const formInline={
            page: 1,
            limit: 30,
            pattername: '',
            pictype: "0",
            listshow:true,
            token: getCookie('usertoken')
             };
    function scrollToTop() {
      window.scrollTo({
        top: 0, // 滚动到页面顶部
        behavior: 'smooth' // 平滑滚动
      });
    }
  const showButton=ref(false);
    const fetchData = () => {
         loading.value = true;
         setTimeout(async () => {
         const newItems = await generateItems(formInline); // 替换为您的数据获取逻辑
         items.value = [...items.value, ...newItems];
         loading.value = false;
        }, 1000); // 模拟延迟
    };
    const previewimage = (index) => {
  const box = document.querySelector('#img-area');
  if (box) {
    const photoViewer = new Viewer(box, {
      inline: false, // 是否启用 inline 模式
      fullscreen: true, // 播放时是否全屏
      title: false, // 是否显示当前图片的标题
      navbar:false,
      toolbar: {  // 显示工具栏
       // 下面各种按钮1显示，0隐藏，可自定义按钮大小和点击事件
        zoomIn: 1, // 放大图片
        zoomOut: 1, //缩小图片
        oneToOne: 1, // 图片比例100%
        reset: 1, // 重置图片大小
        prev: 1, // 查看上一张图片
        play: 0, // 播放图片
        next: 1,// 查看下一张图片
        rotateLeft: 1,// 向左旋转图片
        rotateRight: 1,// 向右旋转图片
        flipHorizontal: 1,// 图片左右翻转
        flipVertical: 1, // 图片上下翻转
      },
      url:'data-original',
      // 定义用于查看的图像的初始索引
      initialViewIndex: index, 
      // 每次关闭查看时触发 
      hide () {
        photoViewer.destroy();
      },
      // 每次关闭查看时触发，在hide之后 
      hidden () {
        photoViewer.destroy();
      },
      // 每次查看时触发
      show () {
        photoViewer.full();
      },
    });
    photoViewer.show();
  }
};
    const generateItems = async (parameter) => {
        // 替换为您的数据生成逻辑
        const newItems = [];
        // const ret= await jxpiclist(parameter).then(res => {
        //   if (res.success) {
        //     formInline.page=formInline.page+1;
        //     newItems.push(res.data.data);
        //     return newItems;
        // }else{}
        // })
        // .catch(err => {
        // })
        try {
              const res = await mypiclist(parameter); // 使用 await 等待异步操作完成
               if (res.success) {
                 formInline.page = formInline.page + 1;
                 newItems.push(...res.data.data); // 将数据展开并添加到 newItems 数组中
            }
        } catch (err) {
         console.error(err);
        }
        return newItems;
    };
    function clikimgtoimg(id){
      router.push('/imgtoimg?id='+id);
    }
    function clikextratoimg(id){
      router.push('/extratoimg?id='+id);
    }
    // 滚动时加载更多
    // const handleScroll = () => {
    //     if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    //     if (!loading.value) {
    //        fetchData(formInline);
    //     }
    //  }
    // };
    function clikcls (types) {
        items.value = [];
        formInline.page =1
        formInline.pictype=types
        fetchData(formInline); 
    }
    const router = useRouter()
    // 组件加载完成时调用
    onMounted(() => {
         document.title = 'AI鞋款创意设计工具';
         const handleScroll = () => {
          // 当页面滚动超过一定距离时，显示按钮；否则隐藏按钮
          showButton.value = window.scrollY > 200; // 200 可以根据需要调整
          if (window.innerHeight + window.scrollY >= (document.body.offsetHeight-400)) {
          if (!loading.value) {
           fetchData(formInline);
          }
          }
        };
        window.addEventListener('scroll', handleScroll);
        onBeforeUnmount(() => {
           // 在组件销毁前移除滚动事件监听器
            window.removeEventListener('scroll', handleScroll);
        });
        let token=getCookie("usertoken");
        if(!token){
            router.push('/login');
        }
        else
        {
         let params={token:token}
         vitoken(params).then(res => {
         if (res.success) { 
            fetchData(formInline); // 初始数据加载
            window.addEventListener('scroll', handleScroll);
         }
         else
         {
            router.push('/login');
         }});
         }
    });
    function hrefurl(url){
    router.push(url);
    }
    // 组件更新完成时调用
    onUpdated(() => {
        console.log("组件更新完成");
    });

    // 组件卸载完成时调用
    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
        console.log("组件卸载完成");
    });

    // 组件加载前时调用
    onBeforeMount(() => {
        console.log("组件加载前");
    });

    // 组件更新前时调用
    onBeforeUpdate(() => {
        console.log("组件更新前");
    });

    // 组件卸载前时调用
    onBeforeUnmount(() => {
        console.log("组件卸载前");
    });

    // 组件激活时调用
    onActivated(() => {
        console.log("组件激活");
    });

    // 组件失活时时调用
    onDeactivated(() => {
        console.log("组件失活");
    });

    // 捕获到后代组件的错误时调用
    onErrorCaptured((error) => {
        // error:错误提示
        console.log("捕获到后代组件的错误", error);
    });

    // 状态跟踪，(组件更新时，跟踪组件里边每个值和方法的变化)
    onRenderTracked((event) => {
        // 当页面有一个update的时候，会生成一个event对象，通过event对象查看代码/程序的问题所在
        console.log(event);
    });

    // 状态触发，(虚拟 DOM 重新渲染被触发时调用,此事件告诉你是什么操作触发了重新渲染，以及该操作的目标对象和键)
    onRenderTriggered((event) => {
        console.log(event);
    });

    //组件实例在服务器上被渲染之前调用（只能在SSR中用）
    onServerPrefetch(() => {
        console.log("onServerPrefetch");
    });
    </script>