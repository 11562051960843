<template>
    <div class="collapse-menu overflow-x-hidden bg-lightwhite font-public text-base font-normal text-slate-600 antialiased dark:bg-darkblue dark:text-indigo-300">
        <div>
            <div class="min-h-screen flex items-center justify-center text-[15px]">
               
                <div class="rounded-lg bg-white p-6 shadow-3xl dark:bg-lightblue dark:shadow-black/10 w-full max-w-md my-10 mx-4">
                    <form action="#">
                    <div class="flex items-center justify-center mb-10">
                        <div class="block min-h-[1px] shrink-0 grow-0 overflow-hidden leading-none">
                            <img src="../assets/images/loginlogo.png" />
                        </div>
                       
                    </div>
                    <h4 class="mb-2 text-[22px] text-center font-medium text-slate-600 dark:text-indigo-300">欢迎登录shoes AI!</h4>
                  
                    <div class="mb-4">
                        <label class="text-xs uppercase text-gray-500 dark:text-indigo-300 font-medium mb-2 inline-block">账 号</label>
                        <input type="text" name="username" v-model="username" class="login-input w-full border-gray-300 shadow-sm" style="border:1px solid #000">
                    </div> 
                   
                    <div class="mb-4">
                        <label class="text-xs uppercase text-gray-500 dark:text-indigo-300 font-medium mb-2 inline-block">密码</label>
                        <input type="password" class="login-input w-full border-gray-300 shadow-sm" name="password" v-model="password" style="border:1px solid #000">
                    </div>
                    <div class="mb-4 flex items-center justify-between gap-3">
                        <label class="inline-flex items-center gap-2">
                            <input type="checkbox" class="form-checkbox text-indigo-600">
                            <span>记住账号</span>
                        </label>
                        <a href="/forgotpass" class="text-sm text-indigo-600 hover:underline">忘记密码?</a>
                    </div>
                    <button type="button" style="background-color:black" class="justify-center w-full inline-flex items-center rounded-md border border-transparent bg-indigo-700 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="_login">
                      登 录
                   </button>
                    <p class="text-center">
                        <span>我是一个新人?</span> <a href="/regist" class="text-indigo-600 hover:underline"><span>创建一个账号</span></a>
                    </p>
                </form>
                </div>
              
            </div>
        </div>
    </div>
    <GlobalToast />
</template>
<script setup>
import { ref,onMounted } from 'vue'
import { login} from '../api/ajaxApi';
import {setCookie,getCookie,delCookie} from '../utils/cookieok';
import { useRouter } from 'vue-router'
const router = useRouter()
const username = ref('')
const password = ref('')
function showGlobalToast(msg) {
      const message = msg
      window.dispatchEvent(new CustomEvent('show-toast', { detail: message }))
    }
 onMounted(() => {
    document.title = '会员登录-AI鞋款创意设计工具';
  })

function _login(){
    if(!username.value||!password.value){
        showGlobalToast("请输入用户名密码");
        return;
    }
            let data={
                username:username.value,
                password:password.value
            }
            login(data).then(res => {
            if (res.success) {
            //   if (rememberpwd) {
            //     //保存帐号到cookie，有效期7天
            //     setCookie('user', this.ruleForm.username, 7)
            //     //保存密码到cookie，有效期7天
            //     setCookie('pwd', this.ruleForm.password, 7)
            //   } else {
            //     delCookie('user')
            //     delCookie('pwd')
            //   }
              //如果请求成功就让他2秒跳转路由
              setTimeout(() => {
                //this.logining = false
                // 缓存token
               // console.log("token8"+res.data.token)
               // localStorage.setItem('logintoken', res.data.token)
                // 缓存用户个人信息
                //localStorage.setItem('userInfo',true)
                // 缓存用户个人信息
                //localStorage.setItem('userdata', JSON.stringify(res.data.token))
                setCookie('usertoken', res.data.token, 7)
                //this.$store.commit('login', 'true')
                router.push({ path: '/pic' })
              }, 1000)
            } else {
              showGlobalToast(res.msg);
              //return false
            }
          })
}
</script>