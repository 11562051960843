<template>
        <div>
            <div class="min-h-screen flex items-center justify-center text-[15px]">
                <form action="#">
                <div class="rounded-lg bg-white p-6 shadow-3xl dark:bg-lightblue dark:shadow-black/10 w-full max-w-md my-10 mx-4">
                    <div class="flex items-center justify-center mb-10">
                        <div class="block min-h-[1px] shrink-0 grow-0 overflow-hidden leading-none">
                            <img src="../assets/images/loginlogo.png" />
                        </div>
                    </div>
                    <h4 class="mb-2 text-[22px] text-center font-medium text-slate-600 dark:text-indigo-300">在这注册账号</h4>
                    <div class="mb-4">
                        <label class="text-xs uppercase text-gray-500 dark:text-indigo-300 font-medium mb-2 inline-block">手机号</label>
                        <input type="text" v-model="username" class="login-input w-full border-gray-300 shadow-sm">
                    </div>
                    <div class="mb-4">
                        <label class="text-xs uppercase text-gray-500 dark:text-indigo-300 font-medium mb-2 inline-block">验证码</label>
                        <div>
                        <input type="text" v-model="code" class="login-input w-full border-gray-300 shadow-sm" style="width: 280px;" >
                        <button type="button" style="background-color: rgba(67, 56, 202, var(--tw-bg-opacity));margin-left:20px;" class="justify-center inline-flex items-center rounded-md border border-transparent bg-indigo-700 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" v-show="!sendCode" @click="getVerifiCode">获取验证码</button>
                        <button type="button" style="background-color: rgba(67, 56, 202, var(--tw-bg-opacity));" class="justify-center inline-flex items-center rounded-md border border-transparent bg-indigo-700 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" v-show="sendCode">{{timeOut}}秒重新获取</button>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label class="text-xs uppercase text-gray-500 dark:text-indigo-300 font-medium mb-2 inline-block">密码</label>
                        <input type="password" v-model="password" class="login-input w-full border-gray-300 shadow-sm">
                    </div>
                    <div class="mb-4">
                        <label class="text-xs uppercase text-gray-500 dark:text-indigo-300 font-medium mb-2 inline-block">请输入公司名或姓名</label>
                        <input type="text" v-model="repassword" class="login-input w-full border-gray-300 shadow-sm">
                    </div>
                    <div class="mb-4 flex items-center gap-1">
                        <label class="inline-flex items-center gap-2">
                            <input type="checkbox" class="form-checkbox text-indigo-600">
                            <span>我同意</span>
                        </label>
                        <a href="javascritp:;" class="text-indigo-600 hover:underline">隐私政策 & 服务协议</a>
                    </div>
                    <button type="button" style="background-color: rgba(67, 56, 202, var(--tw-bg-opacity));" class="justify-center w-full inline-flex items-center rounded-md border border-transparent bg-indigo-700 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="_regesert">注 册</button>
                    <p class="text-center">
                        <span>已经有账户?</span> <router-link to="/login" class="text-indigo-600 hover:underline">登录</router-link>
                    </p>
                </div>
            </form>
            </div>
        </div>
        <GlobalToast />
</template>
<script setup>
   import { ref,onMounted } from 'vue'
   import { useRouter } from 'vue-router'
   import {setCookie,getCookie,delCookie} from '../utils/cookieok';
   import { reg,getmcode } from '../api/ajaxApi';
   const router = useRouter()
   const username = ref('')
   const code=ref('')
   const password= ref('')
   const repassword=ref('')
   let sendCode=ref(false)
   let timeOut=ref(60)
   function showGlobalToast(msg) {
      const message = msg
      window.dispatchEvent(new CustomEvent('show-toast', { detail: message }))
    }
    onMounted(() => {
        document.title = '会员注册-AI鞋款创意设计工具';
    })
        
   function getVerifiCode(){
            if (!username.value) {
                showGlobalToast("请输入手机号");
                return;
            }
            const modelmz = /^1[34578]\d{9}$$/
            if (!modelmz.test(username.value)) {
                showGlobalToast('请输入有效手机号')
                 return
            }
            sendCode.value = true;
            setTimeOut();
            let params={ username:username.value}
            getmcode(params).then(res => {
            if (res.success) {
                showGlobalToast(res.msg);
            }
            });
 
          }
          function setTimeOut() {
           let timer = setTimeout(() => {
            setTimeOut();
           if (timeOut.value > 0) {
              timeOut.value--;
             }
           }, 1000);
           if (timeOut.value <= 0) {
              sendCode.value = false;
              timeOut.value = 60;
              clearTimeout(timer);
            }
          }
   function _regesert(){
            console.log(username.value)
            if (!username.value) {
                showGlobalToast("请输入用户名密码");
                return;
            }
            const modelmz = /^1[34578]\d{9}$$/
            if (!modelmz.test(username.value)) {
                showGlobalToast('请输入有效手机号')
                 return
            }
            if(!code.value){
                showGlobalToast("请输入验证码");
                return;
            }
            if(!password.value){
                showGlobalToast("请输入密码");
                return;
            }
            if(!repassword.value){
                showGlobalToast("请输入公司名或姓名");
                return;
            }
            
            let params={
                mobile:username.value,
                password:password.value,
                code:code.value,
                cname:repassword.value
                }
            reg(params).then(res=>{
                if(res.success){
                    showGlobalToast("注册成功")
                    setTimeout(() => {
                     setCookie('usertoken', res.data.token, 7)
                     router.push({path: '/pic'})
                    }, 1000)
                }
                else{
                    showGlobalToast(res.msg)
                }
            })
          }
</script>