<template>
    <div>
      <main class="z-0 hidden h-screen w-screen min-w-[1120px] flex-col items-center justify-center gap-0 md:flex">
        <Header></Header>
        <div class="relative flex h-full max-h-[calc(100%-48px)] w-full justify-end">
          <!--左边菜单 start  <div class="w-17 mt-4 ml-4 mb-4 min-w-[288px] max-w-[368px] border-[0.5px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md">-->
        <div class="absolute inset-y-4 left-4 z-10 flex w-[16%] min-w-[308px] max-w-[338px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md" data-projection-id="9" style="opacity: 1; transform: none;">
          <!-- <div class="w-17 mt-4 ml-4 mb-4 min-w-[288px] max-w-[368px] border-[0.5px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md" data-projection-id="9" style="opacity: 1; transform: none;"> -->
          <div class="relative flex h-11 min-h-[44px] w-full items-center justify-center border-b-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)]">
                
                <div class="flex items-center gap-2 text-[var(--accent-base)]">
                 
                  <div class="select-none text-base font-medium">会员中心</div>
                </div>
                
              </div>
              <div dir="ltr" class="relative flex h-full w-full overflow-auto" style="position: relative; --radix-scroll-area-corner-width: 0px; --radix-scroll-area-corner-height: 0px;">
                
                <div class="w-full relative inner" style="overflow: hidden scroll;">
                  <div style="min-width: 100%; display: table;">
                    <div class="flex w-full flex-col gap-6 p-4">
                    
                  <nav class="flex w-72 min-w-[288px] flex-col items-center justify-start gap-4 bg-[var(--bg-sub)] p-4">
             
              <div class="flex w-full flex-col items-center justify-center gap-1">
                  <a class="flex h-8 w-full select-none items-center justify-between gap-1.5 rounded-[10px] px-2 transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--accent-base)] focus:ring-offset-2 focus:ring-offset-[var(--bg-sub)] bg-[var(--accent-bg)] text-[var(--accent-base)]" href="/profile">
                      <div class="flex items-center gap-1">
                          <div class="h-5 w-5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4">
                              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                              <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                          </svg>
                      </div>
                      <span class="text-sm font-medium">个人资料</span>
                  </div>
              </a>
              <a class="flex h-8 w-full select-none items-center justify-between gap-1.5 rounded-[10px] px-2 transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--accent-base)] focus:ring-offset-2 focus:ring-offset-[var(--bg-sub)] bg-transparent text-[var(--label-muted)] hover:bg-[var(--bg-shade)] hover:text-[var(--label-base)]" href="/repassword">
                  <div class="flex items-center gap-1">
                      <div class="h-5 w-5">
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.129 3.879A3 3 0 0 1 5.25 3h13.5a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V6a3 3 0 0 1 .879-2.121Zm.621 2.523V6a1.5 1.5 0 0 1 1.5-1.5h13.5a1.5 1.5 0 0 1 1.5 1.5v.402A3 3 0 0 0 18.75 6H5.25a3 3 0 0 0-1.5.402ZM20.25 9a1.5 1.5 0 0 0-1.5-1.5H5.25A1.5 1.5 0 0 0 3.75 9v.402A3 3 0 0 1 5.25 9H9a.75.75 0 0 1 .75.75 2.25 2.25 0 0 0 4.5 0A.75.75 0 0 1 15 9h3.75a3 3 0 0 1 1.5.402V9Zm0 3a1.5 1.5 0 0 0-1.5-1.5h-3.076a3.75 3.75 0 0 1-7.348 0H5.25a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-6Z" fill="currentColor"></path>
                          </svg>
                      </div>
                      <span class="text-sm font-medium">修改密码</span>
                  </div>
              </a>
              <a class="flex h-8 w-full select-none items-center justify-between gap-1.5 rounded-[10px] px-2 transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--accent-base)] focus:ring-offset-2 focus:ring-offset-[var(--bg-sub)] bg-transparent text-[var(--label-muted)] hover:bg-[var(--bg-shade)] hover:text-[var(--label-base)]" href="/conlog">
                  <div class="flex items-center gap-1">
                      <div class="h-5 w-5">
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 3.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM2.25 6a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z" fill="currentColor"></path>
                              <path d="M8.25 6A.75.75 0 0 1 9 5.25h5a3.75 3.75 0 1 1 0 7.5h-4a2.25 2.25 0 1 0 0 4.5h5a.75.75 0 0 1 0 1.5h-5a3.75 3.75 0 1 1 0-7.5h4a2.25 2.25 0 1 0 0-4.5H9A.75.75 0 0 1 8.25 6Z" fill="currentColor"></path>
                              <path d="M18 15.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM14.25 18a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z" fill="currentColor"></path>
                          </svg>
                      </div>
                      <span class="text-sm font-medium">消费记录</span>
                  </div>
                  
              </a>
          </div>
      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <!--左边菜单 end-->
          <!--中间开始 start-->
          <div class="main-box " style="margin-left:358px">
            <div class="h-full max-h-[calc(100%-40px)] flex items-center gap-3 rounded-3xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-3 shadow-md mt-4 mb-4" data-projection-id="12" style="opacity: 1; transform: none;">
              <div class="relative flex h-full w-full items-center justify-center" style="">
                <div style="position: relative; width: 100%; height: 100%;  pointer-events: auto; touch-action: none;" >
                      <div class="flex w-full flex-col gap-1 border-b-[0.5px] border-[var(--bg-border)] py-8">
                              <div class="text-3xl font-bold text-[var(--label-title)]">个人资料</div>
                      </div>
                      <div class="flex w-full max-w-sm gap-3 mt-4 mx-4">
                          <div class="w-56 text-[var(--label-muted)]" style="line-height:40px;"> 注册手机 </div>  
                          <div class="flex w-full flex-col items-start justify-center gap-2 pointer-events-none touch-none select-none opacity-40">
                              <input type="text" class="style_TextInput__4mhwe inline-flex w-full appearance-none items-center border border-solid border-[var(--bg-border)] bg-[var(--bg-base)] px-3 font-normal text-[var(--label-base)] caret-[var(--accent-base)] outline-none transition duration-100 ease-out placeholder:select-none placeholder:text-[var(--label-faint)] hover:border-[var(--bg-border-strong)] focus:border-[var(--accent-base)] focus:outline-none h-10 rounded-xl text-sm" data-status="default" :value="mobile.value">
                          </div>
                      </div> 
                      <div class="flex w-full max-w-sm gap-3 mt-4 mx-4">
                          <div class="w-56 text-[var(--label-muted)]" style="line-height:40px;"> 公司名称 </div>  
                          <div class="flex w-full flex-col items-start justify-center gap-2 pointer-events-none touch-none select-none opacity-40">
                              <input type="text" class="style_TextInput__4mhwe inline-flex w-full appearance-none items-center border border-solid border-[var(--bg-border)] bg-[var(--bg-base)] px-3 font-normal text-[var(--label-base)] caret-[var(--accent-base)] outline-none transition duration-100 ease-out placeholder:select-none placeholder:text-[var(--label-faint)] hover:border-[var(--bg-border-strong)] focus:border-[var(--accent-base)] focus:outline-none h-10 rounded-xl text-sm" data-status="default" :value="company.value">
                          </div>
                      </div>
                      <div class="flex w-full max-w-sm gap-3 mt-4 mx-4">
                          <div class="w-56 text-[var(--label-muted)]" style="line-height:40px;"> 到期时间 </div>  
                          <div class="flex w-full flex-col items-start justify-center gap-2 pointer-events-none touch-none select-none opacity-40">
                              <input type="text" class="style_TextInput__4mhwe inline-flex w-full appearance-none items-center border border-solid border-[var(--bg-border)] bg-[var(--bg-base)] px-3 font-normal text-[var(--label-base)] caret-[var(--accent-base)] outline-none transition duration-100 ease-out placeholder:select-none placeholder:text-[var(--label-faint)] hover:border-[var(--bg-border-strong)] focus:border-[var(--accent-base)] focus:outline-none h-10 rounded-xl text-sm" data-status="default" :value="enddate.value">
                          </div>
                      </div>
                      <div class="flex w-full max-w-sm gap-3 mt-4 mx-4">
                          <div class="w-56 text-[var(--label-muted)]" style="line-height:40px;"> 图片数 </div>  
                          <div class="flex w-full flex-col items-start justify-center gap-2 pointer-events-none touch-none select-none opacity-40">
                              <input type="text" class="style_TextInput__4mhwe inline-flex w-full appearance-none items-center border border-solid border-[var(--bg-border)] bg-[var(--bg-base)] px-3 font-normal text-[var(--label-base)] caret-[var(--accent-base)] outline-none transition duration-100 ease-out placeholder:select-none placeholder:text-[var(--label-faint)] hover:border-[var(--bg-border-strong)] focus:border-[var(--accent-base)] focus:outline-none h-10 rounded-xl text-sm" data-status="default" :value="picnum.value">
                          </div>
                      </div>
                      <!-- <div class="w-full max-w-sm gap-3 mt-4 mx-4 text-right">
                        <button disabled="" type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 px-4 h-10 rounded-xl gap-2 text-base border-none bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)] pointer-events-none cursor-default touch-none opacity-40" data-type="primary">保存</button>
                     
                    </div> -->
               </div> 
               
               </div>
            </div>
          </div>
          <!--中间开始 end-->
        </div>
      </main>
      </div>
    </template>
    
    
    <script setup>
    import { modellist,userinfo,vitoken } from '../api/ajaxApi';
    import {setCookie,getCookie,delCookie} from '../utils/cookieok';
    import {formatDate} from '../utils/date';
    import {Msg} from '@/utils/tools';
    import Header from '../components/Header.vue'
    import { RadioGroup, RadioGroupLabel, RadioGroupOption,Popover, PopoverButton, PopoverGroup, PopoverPanel,Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
    import {
    ArrowPathIcon,
    Bars3Icon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    DocumentChartBarIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
    } from '@heroicons/vue/24/outline'
    import { useRouter } from 'vue-router'
    const router = useRouter()
    const route = router.value;
    import { ChevronDownIcon } from '@heroicons/vue/20/solid'
    import {
        ref,
        toRefs,
        onMounted, // 组件加载完成时调用
        onUpdated, // 组件更新完成时调用
        onUnmounted, // 组件卸载完成时调用
        onBeforeMount, // 组件加载前时调用
        onBeforeUpdate, // 组件更新前时调用
        onBeforeUnmount, // 组件卸载前时调用
        onActivated, // 组件激活时时调用
        onDeactivated, // 组件失活时时调用
        onErrorCaptured, // 捕获到后代组件的错误时调用
        onRenderTracked, // 状态跟踪，(组件更新时，跟踪组件里边每个值和方法的变化)
        onRenderTriggered, // 状态触发，(虚拟 DOM 重新渲染被触发时调用,此事件告诉你是什么操作触发了重新渲染，以及该操作的目标对象和键)
        onServerPrefetch, // 服务器上被渲染之前调用
    } from "vue";
   
  
  
  
  
    const navigation = [
    { name: '首页', href: '/', current: false },
    { name: '文创鞋款', href: '/txttoimg', current: false },
    { name: '以图生图', href: '/imgtoimg', current: false },
    { name: '多款合成', href: '/multitoimg', current: false },
    { name: '局部重绘', href: '/extratoimg', current: false },
    { name: '配色换料', href: '/colortoimg', current: false },
    { name: '手稿配色', href: '/cannytoimg', current: true },
    { name: '换底换面', href: '/sutoimg', current: false },
  ]
  
  
  
  //图片上传处理开始
  
  function hrefurl(url){
    router.push(url);
  }
  
  const company=ref("");
  const mobile=ref("");
  const enddate=ref("");
  const picnum=ref("");
  //图片上传处理结束
  onMounted(() => {
    binstyle()
    document.title = 'AI鞋款创意设计工具';
  })

  
  
  function binstyle(){
    let params={
        token:getCookie("usertoken"),
    }
    vitoken(params).then(res => {
        if (res.success) {
            userinfo(params).then(ress=>{
                 if(ress.success){
                    mobile.value=ref(ress.data.mobile)
                    company.value=ref(ress.data.company)
                    enddate.value=ref(ress.data.enddate)
                    picnum.value=ref(ress.data.picnum)

                    setCookie('userinfo', ress.data, 7)
                 }
            })
        }
        else
        {
            router.push('/login');
        }
        })
  }

  
    </script>