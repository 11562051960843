<template>
    <transition name="fade" mode="out-in">
      <div v-if="isVisible" class="global-toast">
        {{ message }}
      </div>
    </transition>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  
  const isVisible = ref(false)
  const message = ref('')
  
  const showToast = (msg) => {
    message.value = msg
    isVisible.value = true
    setTimeout(() => {
      isVisible.value = false
    }, 3000) // 3秒后自动隐藏提示
  }
  
  onMounted(() => {
    // 监听全局事件，以便从任何地方触发提示
    window.addEventListener('show-toast', (event) => {
      showToast(event.detail)
    })
  })
  </script>
  
  <style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  .global-toast {
    position: fixed;
    left: 50%;
    top: 50%;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    transform: translate(-50%, -50%);
  }
  </style>
  